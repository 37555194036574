var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "tit": "Minart's Non-lit Ad Solutions",
      "bg": "/images/sub/visual/sv--product.jpg"
    }
  }, [_c('p', {
    staticClass: "page-text line-height-15 mt-8 mt-lg-20 color-grey-c"
  }, [_vm._v(" The EZFLIP™ safety opening and closing frame allows anyone to change printed media easily and safely. The intuitive "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" EZMAG™ magnet frame for quick and easy print replacement. Discover our non-lit product family which features "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" low-profile design with minimal installation surface protrusion, more economic, and easier maintenance. ")])]), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('router-link', {
    staticClass: "text-btn d-flex align-center",
    attrs: {
      "to": "/en"
    }
  }, [_c('i', {
    staticClass: "icon icon-left-arrow mr-8"
  }), _vm._v("Back to main")]), _c('div', {
    staticClass: "mt-12 mt-lg-20"
  }, [_c('v-row', _vm._l(_vm.programs, function (program, idx) {
    var _program$thumb;
    return _c('v-col', {
      key: idx,
      attrs: {
        "cols": "12",
        "sm": "6",
        "md": "4"
      }
    }, [_c('div', {
      staticClass: "h-100",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.show(program);
        }
      }
    }, [_c('card-primary', [_c('div', {
      staticClass: "thumb"
    }, [_c('div', {
      staticClass: "thumb__inner",
      style: `background-image:url('${(_program$thumb = program.thumb) === null || _program$thumb === void 0 ? void 0 : _program$thumb.url}');`
    })]), _c('div', {
      staticClass: "w-100 pa-20 pa-md-30"
    }, [_c('p', {
      staticClass: "page-text--sm color-grey-7 line-height-1 mb-2 mb-lg-6"
    }, [_vm._v(" " + _vm._s(program.subTitle) + " ")]), _c('h5', {
      staticClass: "tit-txt font-weight-bold line-height-13 word-keep-all"
    }, [_vm._v(" " + _vm._s(program.subject) + " ")]), _c('v-divider', {
      staticClass: "border-color-dark my-12 my-lg-20"
    }), _c('p', {
      staticClass: "page-text--sm line-height-13"
    }, [_vm._v(" " + _vm._s(program.content) + " ")])], 1)])], 1)]);
  }), 1)], 1), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }