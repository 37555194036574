<template>
    <client-page>
        <sub-visual tit="Minart's Non-lit Ad Solutions" bg="/images/sub/visual/sv--product.jpg">
            <p class="page-text line-height-15 mt-8 mt-lg-20 color-grey-c">
                The EZFLIP™ safety opening and closing frame allows anyone to change printed media easily and safely. The intuitive <br class="d-none d-xl-block">
                EZMAG™ magnet frame for quick and easy print replacement. Discover our non-lit product family which features <br class="d-none d-xl-block">
                low-profile design with minimal installation surface protrusion, more economic, and easier maintenance.
            </p>
        </sub-visual>

        <section class="section">
            <v-container>
                <router-link to="/en" class="text-btn d-flex align-center"><i class="icon icon-left-arrow mr-8"></i>Back to main</router-link>
                <div class="mt-12 mt-lg-20">
                    <v-row>
                        <v-col cols="12" sm="6" md="4" v-for="program, idx in programs" :key="idx">
                            <div @click="show(program)" style="cursor: pointer" class="h-100">
                                <card-primary>
                                    <div class="thumb">
                                        <div class="thumb__inner" :style="`background-image:url('${program.thumb?.url}');`"></div>
                                    </div>
                                    <div class="w-100 pa-20 pa-md-30">
                                        <p class="page-text--sm color-grey-7 line-height-1 mb-2 mb-lg-6">
                                            {{program.subTitle}}
                                        </p>
                                        <h5 class="tit-txt font-weight-bold line-height-13 word-keep-all">
                                            {{program.subject}}
                                        </h5>
                                        <v-divider class="border-color-dark my-12 my-lg-20" />
                                        <p class="page-text--sm line-height-13">
                                            {{program.content}}
                                        </p>
                                    </div>
                                </card-primary>
                            </div>
                            <!-- <card-primary>
                                <div class="thumb" @click="show()">
                                    <div class="thumb__inner" :style="`background-image:url('${program.thumb?.url}');`"></div>
                                </div>
                                <div class="w-100 pa-20 pa-md-30">
                                    <p class="page-text--sm color-grey-7 line-height-1 mb-2 mb-lg-6">
                                        {{program.subject}} 시리즈
                                    </p>
                                    <h5 class="tit-txt font-weight-bold line-height-13 word-keep-all">
                                        {{program.subject}}
                                    </h5>
                                    <v-divider class="border-color-dark my-12 my-lg-20" />
                                    <p class="page-text--sm line-height-13">
                                        {{program.content}}
                                    </p>
                                </div>
                            </card-primary> -->
                        </v-col>
                    </v-row>
                </div>

                <div class="v-pagination-wrap">
                    <v-pagination v-model="page" :length="pageCount" @input="search"/>
                </div>

            </v-container>
        </section>


        <!-- <v-container>
            <div class="tit-wrap text-center">
                <h2 class="tit tit--lg">비조명 광고 솔루션</h2>
            </div>
            <div v-for="program, idx in programs" :key="idx">
                <v-card> 
                    <v-card-subtitle>{{program.groups}}시리즈</v-card-subtitle>
                    <v-card-title>{{program.subject}}</v-card-title>
                    <v-divider />
                    <v-content>{{program.content}}</v-content>
                </v-card>
            </div>
        </v-container> -->

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/en/templates/ClientPage.vue";
import SubVisual from "@/components/client/en/sub/sub-visual.vue";
import CardPrimary from "@/components/dumb/card-primary.vue";

import api from "@/api";

export default {
    components: {
        ClientPage,
        SubVisual,
        CardPrimary,
    },
    data() {
        return {
            programs: [],
            code: this.$route.query.code,

            page: 1,
            pageCount: 0,
			limit: 6,
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.search()
        },
        async search() { 
            let { summary, programs } = await api.v1.auction.programs.gets({ 
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                    sort: "boardNum"
                },
                params: { code: "non-lighting", language: "en" }});

            this.programs = programs;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);

        },
        show(program) {
            this.$router.push(`/en/series/product?category=${program.subject}`);
        }
    },
    watch: {
        "$route.query.category"() {
            this.init();
        }
    }
};
</script>
